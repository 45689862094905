@use '/node_modules/@bbraun/cortex/styles/utilities/variables.scss' as v

.mat-row, .mat-header-row, .mat-footer-row
  border-bottom-color: v.$bbraun-gray-40

table.mat-mdc-table

  th.mat-mdc-header-cel
    padding-top: v.$bbraun-spacing-sm
    padding-bottom: v.$bbraun-spacing-sm
    margin-bottom: v.$bbraun-spacing-s
    color: v.$bbraun-gray-80
    font-size: v.$bbraun-font-size-medium
    font-weight: v.$bbraun-font-weight-bold

    .mat-sort-header-sorted
      color: v.$bbraun-gray-120

  td.mat-cell, th.mat-header-cell, td.mat-footer-cell
    padding-right: v.$bbraun-spacing-sm !important

    &:first-of-type
      padding-left: v.$bbraun-spacing-sm !important

    &.mat-column-actions
      text-align: right
      padding-right: v.$bbraun-spacing-s !important

  tr.mat-row, tr.mat-footer-row
    height: 64px

  .th-border-none th.mat-header-cell
    border-bottom: none !important

  tr.mat-header-row
    height: auto

  .mat-row:hover
    background: inherit !important

.mat-sort-header-arrow
  display: block !important
  visibility: visible !important

table.mat-mdc-table.compact-table
  th.mat-mdc-header-cell
    padding-top: v.$bbraun-spacing-s
    padding-bottom: v.$bbraun-spacing-s
    font-weight: v.$bbraun-font-weight-normal

  tr.mat-mdc-row, tr.mat-mdc-footer-row
    height: 44px

  td.mat-mdc-cell, th.mat-mdc-header-cell, td.mat-mdc-footer-cell
    &:first-of-type
      padding-left: 0 !important

    &.mat-column-actions
      padding-right: 0 !important
