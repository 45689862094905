@use '/node_modules/@bbraun/cortex/styles/utilities/variables.scss' as v

.mat-mdc-list.mat-mdc-list-base[dense]
  padding: 0

  &.list-with-border
    border-radius: v.$bbraun-border-radius
    border: 1px solid v.$bbraun-gray-40
    padding-right: v.$bbraun-spacing-sm
    padding-left: v.$bbraun-spacing-sm

    .mat-mdc-list-item:last-of-type
      border-bottom: none

  .mat-mdc-list-item
    height: auto
    border-bottom: 1px solid v.$bbraun-gray-40

    .mdc-list-item__content > span
      display: flex
      align-items: flex-start
      padding: v.$bbraun-spacing-s 0
      font-size: v.$bbraun-font-size-medium

      & > :nth-last-of-type(2)
        color: v.$bbraun-gray-80
        padding-right: v.$bbraun-spacing-sm
        min-width: 35%
        max-width: 35%
        flex-basis: 35%

      & > :nth-last-of-type(2)
        word-break: break-all

.mdc-evolution-chip__graphic .mat-mdc-chip-graphic
  display: none !important

.mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic
  min-width: 36px !important

.mdc-evolution-chip__checkmark
  display: none !important

.mat-mdc-autocomplete-panel .mdc-list-item__primary-text
  width: 100% !important
  > div
    width: inherit !important

.mat-mdc-list.mat-mdc-list-base
  .mdc-list-item__content > span
      display: flex
      flex-direction: row
      align-items: center
      box-sizing: border-box
      position: relative
      height: inherit
