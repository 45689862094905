@use '/node_modules/@bbraun/cortex/styles/utilities/variables.scss' as v

/*
 Use this file if you want to override any of the Cortex or Angular Material styles
 */

// So that the arrows are centered in the tables
.mat-sort-header-arrow
  line-height: 24px


.cx-image-container.cx-image-fit-cover
  .cx-image
    object-fit: contain !important

// So that the selected/active language is highlighted when the language picker menu opens
.active-language-pair
  button
    background: rgba(v.$bbraun-clickable, v.$bbraun-opacity-hover)

// Removes the close icon of the Cortex autocomplete for an Item (Product) search
.cortex-item-autocomplete
  .cx-search-bar-icon-close
    display: none

// Positions the info button on the add Item accordion to the end
.add-item-expansion-panel
  .mat-expansion-panel-header-description
    justify-content: end

// So that the disabled reason autocomplete input is with the color of the other surrounding inputs
.disabled-wrapper-color
  .mat-mdc-text-field-wrapper
    background-color: v.$bbraun-gray-20 !important

// Side Bar backdrop only covers part of the screen, depending on parents height being 100% or less
// Making it fixed resolves this issue and the backdrop completely covers the whole screen
.segments-wrapper
  .mat-drawer-backdrop
    position: fixed !important

// Same as above, but for the projects overview Side Bar
.sidenav-wrapper
  .mat-drawer-backdrop
    position: fixed !important

// Cortex left their search bar in the header and if you do not use it - it still takes up space...
// Anyway, this sets the maximum width of the header title, so that it does not push the quickbar buttons out of view
.aia-header
  .aia-header-title
    h1
      max-width: 70vw
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

// Hide the re-arrange button from the consolidate Repair Companies table (since it's only one column)
// Hide the re-arrange button from the downloads table (since there's no need for rearrangement)
.repair-companies-table, .downloads-table
  #table-menu-button
    display: none

// So that all of the download cards are equal height on large screens
.download-cards-wrapper
  .cx-card
    height: 100%

// For when selecting detailed findings on product classes - each photo col to be equal height
.photo-selectable-cols
  .cx-card
    display: flex
    height: 100%

// The following two classes create a green border/gray border on a Cortex expansion panel (used to indicate whether
// that particular panel is included (or not) in the report generation)
.display-panel-in-the-report
  .cx-expansion-panel
    border-left: 3px solid v.$bbraun-green-60

.do-not-display-the-panel-in-the-report
  .cx-expansion-panel
    border-left: 3px solid v.$bbraun-gray-100

// So that the delete icon and the text is in the center of the sidebar when deleting a Speciality
.speciality-deletion-sidebar-content > div:first-of-type
  height: 100%

// Removing the App/Profile settings button from the profile dropdown menu
.cx-profile-dropdown-menu
  mat-divider:first-of-type, cx-menu-item:first-of-type
    display: none

// Making sure that the selected chip's font color is the active one (not white)
.mat-mdc-chip-selected.mdc-evolution-chip--selected
  button
    .mdc-evolution-chip__text-label.mat-mdc-chip-action-label
      color: v.$bbraun-green-60

// On smaller screen sizes, the `Create Project` floating button is on top of the Projects table's pagination,
// so we're moving them above the table
@media (max-width: 1366px)
  .project-adding-floating-button
    button
      top: 85px !important
      right: 25px !important

// Items table statistics
.items-table-toolbar
  .toolbar-container
    .search-template
      max-width: 100%
    .button-template
      display: none

@media (max-width: 1024px)
  // Make the Consolidation stepper scrollable on smaller devices
  .data-consolidation-wrapper-row
    .cx-horizontal-header-container
      overflow-x: scroll
  // Shrink the header title on tablets
  .aia-header
    .aia-header-title
      h1
        max-width: 60vw

@media (max-width: 800px)
  // Shrink the header title on phones
  .aia-header
    .aia-header-title
      h1
        max-width: 35vw
