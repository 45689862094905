@use 'sass:map'
@use '/node_modules/@bbraun/cortex/styles/utilities/variables.scss' as v

.section
  width: 45%

.w-35
  width: 35%

.w-40
  width: 40%

.snackbar
  max-width: 350px

.required-icon
  padding-left: 10px
  position: relative
  &:before
    color: v.$bbraun-error
    font-size: v.$bbraun-font-size-big
    display: block
    content: "*"
    position: absolute
    width: 10px
    height: 10px
    left: 0
    top: -4px

.cursor-pointer
  cursor: pointer

.text-decoration-none
  text-decoration: none

// use on tool tips that you want content with `\n` to be on a new line
.white-space-pre-line
    white-space: pre-line

// style text in a way that ensures it fits within its containing element and adds an
// ellipsis ("...") to indicate that the text has been truncated
.ellipsis-text
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

// Additional flex styles
.flex-1
  flex: 1

.justify-content-stretch
  justify-content: stretch

.align-self-stretch
  align-self: stretch

.align-self-center
  align-self: center

// Class that applies a blur effect to give a visual indication that the element is disabled
.blur-overlay
  // Prevents User interaction
  pointer-events: none
  // To visually indicate that the section is disabled
  opacity: 0.6
  // Blur the content slightly
  filter: blur(2px)

.mr-auto
  margin-right: auto

// Custom scrollbar styles start
///* Firefox */
//*
//  scrollbar-width: auto
//  scrollbar-color: $bbraun-green-60 $bbraun-green-10
//
///* Other browsers */
//*::-webkit-scrollbar
//  width: 14px
//
//*::-webkit-scrollbar-track
//  background: $bbraun-green-10
//
//*::-webkit-scrollbar-thumb
//  background-color: $bbraun-green-60
//  border-radius: 6px
//  border: 2px solid $bbraun-green-70
// Custom scrollbar styles end

// Properly positioning the connectivity icon (wifi_on, wifi_off), so that the cx-header-quickbar does not flicker on
// online/offline change
.network-status-icon-wrapper
  height: 38px
  margin-right: 4px
  padding-top: 4px

@media screen and (min-width: map.get(v.$breakpoints, 'xxl'))
  .section
    width: 48%
